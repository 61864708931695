import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UiComboDatePickerModule } from '@fgp/components/combo-datepicker/ui-combo-datepicker';
import { FeatureComboDatepickerComponent } from './feature-combo-datepicker/feature-combo-datepicker.component';

@NgModule({
  imports: [CommonModule, UiComboDatePickerModule],
  exports: [FeatureComboDatepickerComponent],
  declarations: [FeatureComboDatepickerComponent]
})
export class FeatureComboDatePickerModule {}
