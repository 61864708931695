import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DateParser } from '@fgp/utils/dates';

@Component({
  selector: 'ui-combo-datepicker',
  templateUrl: './ui-combo-datepicker.component.html',
  styleUrls: ['./ui-combo-datepicker.component.scss'],
  standalone: false
})
export class UiComboDatepickerComponent {
  DAYS = this.generateArray(31);
  MONTHS = this.generateArray(12);
  YEARS = this.generateArray(new Date().getFullYear(), 1923);
  day!: string;
  month!: string;
  year!: string;

  @Input()
  set date(value: Date) {
    if (value) {
      const date = DateParser.obtenerFechaFormatoJsonb(value).split('-');
      this.year = date[0];
      this.month = date[1];
      this.day = date[2];
    }
  }
  get date(): Date {
    return this._dateValue;
  }

  _dateValue!: Date;

  @Output() dateRangeSelection = new EventEmitter<string>();

  generateDate() {
    this.dateRangeSelection.emit(`${this.month}/${this.day}/${this.year}`);
  }

  generateArray(end: number, first?: number): string[] {
    const arr = [];
    for (let i = first ?? 1; i <= end; ++i) {
      arr.push(`${i}`.padStart(2, '0'));
    }
    return arr;
  }
}
