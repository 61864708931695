<div class="d-flex">
  <ng-select
    [items]="DAYS"
    [searchable]="false"
    [clearable]="false"
    placeholder="{{ 'datos-personales.combo.fecha-nacimiento.dia' | translate }}"
    (change)="generateDate()"
    [(ngModel)]="day"
    class="w-50"
  >
  </ng-select>
  <ng-select
    [searchable]="false"
    [clearable]="false"
    placeholder="{{ 'datos-personales.combo.fecha-nacimiento.mes' | translate }}"
    (change)="generateDate()"
    [(ngModel)]="month"
    class="w-100 mx-4"
  >
    @for (month of MONTHS; track month) {
      <ng-option [value]="month">{{ 'commons.month.' + month | translate }}</ng-option>
    }
  </ng-select>
  <ng-select
    [items]="YEARS"
    [searchable]="false"
    [clearable]="false"
    placeholder="{{ 'datos-personales.combo.fecha-nacimiento.anyo' | translate }}"
    (change)="generateDate()"
    [(ngModel)]="year"
    class="w-50"
  >
  </ng-select>
</div>
