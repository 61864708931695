import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateParser } from '@fgp/utils/dates';

@Component({
  selector: 'feature-combo-datepicker',
  templateUrl: './feature-combo-datepicker.component.html',
  styleUrls: ['./feature-combo-datepicker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: FeatureComboDatepickerComponent,
      multi: true
    }
  ],
  standalone: false
})
export class FeatureComboDatepickerComponent implements ControlValueAccessor {
  private propagateChange: any;
  date!: Date;

  dateRangeSelection(event: string) {
    const date = new Date(event);
    this.propagateChange(this.isDate(date) ? DateParser.obtenerFechaYHoraMinutoSegundoConFormatoJsonb(date) : null);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  writeValue(obj: string): void {
    if (obj && this.isDate(new Date(obj))) {
      this.date = new Date(obj);
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  registerOnTouched(fn: any): void {}
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setDisabledState?(isDisabled: boolean): void {}

  isDate(date: Date): boolean {
    return !isNaN(date.getTime());
  }
}
